import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useGlobalContext } from "../context/GlobalContext";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import ArticleIcon from "@mui/icons-material/Article";
import { useCookies } from "react-cookie";

export default function HomePageSearch() {
  const globalContext = useGlobalContext();

  const [currentDateStart, setCurrentDateStart] = useState<any>(new Date());
  const [currentTimeStart, setCurrentTimeStart] = useState<any>("");
  const [currentDateEnd, setCurrentDateEnd] = useState<any>(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [currentTimeEnd, setCurrentTimeEnd] = useState<any>("");

  const [cookies, setCookie, removeCookie] = useCookies([
    "name",
    "phone",
    "email",
    "picture",
  ]);

  return (
    <Box
      m={{ xs: 2, md: 4 }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "-webkit-fill-available",
        maxWidth: "380px",
      }}
    >
      <Box
        maxWidth={{ xs: "180px", sm: "220px" }}
        p={{ xs: 4, sm: 3, md: 2 }}
        marginRight={2}
      >
        <img src={"logo-new.png"} alt={"main"} width={"100%"} />
      </Box>
      <Box
        p={{ xs: 2, sm: 3, md: 3 }}
        sx={{
          backgroundColor: "white",
          borderRadius: 3,
          width: "-webkit-fill-available",
        }}
      >
        <Box component="form" noValidate>
          <Typography variant="h6" sx={{ textAlign: "center", pb: 3 }}>
            Media Equipment Rentals
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={{ xs: 2, md: 2 }}>
              {/* START DATE */}
              <Grid item xs={7}>
                <DatePicker
                  label="From"
                  value={currentDateStart}
                  minDate={new Date()}
                  onChange={(value) => {
                    setCurrentDateStart(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              {/* START TIME SELECTOR */}
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={currentTimeStart}
                    label="Time"
                    onChange={(event) => {
                      setCurrentTimeStart(event.target.value as string);
                    }}
                  >
                    <MenuItem value={", 9:00:00 AM"}>9:00 AM</MenuItem>
                    <MenuItem value={", 9:30:00 AM"}>9:30 AM</MenuItem>
                    <MenuItem value={", 10:00:00 AM"}>10:00 AM</MenuItem>
                    <MenuItem value={", 10:30:00 AM"}>10:30 AM</MenuItem>
                    <MenuItem value={", 11:00:00 AM"}>11:00 AM</MenuItem>
                    <MenuItem value={", 11:30:00 AM"}>11:30 AM</MenuItem>
                    <MenuItem value={", 12:00:00 PM"}>12:00 PM</MenuItem>
                    <MenuItem value={", 12:30:00 PM"}>12:30 PM</MenuItem>
                    <MenuItem value={", 1:00:00 PM"}>1:00 PM</MenuItem>
                    <MenuItem value={", 1:30:00 PM"}>1:30 PM</MenuItem>
                    <MenuItem value={", 2:00:00 PM"}>2:00 PM</MenuItem>
                    <MenuItem value={", 2:30:00 PM"}>2:30 PM</MenuItem>
                    <MenuItem value={", 3:00:00 PM"}>3:00 PM</MenuItem>
                    <MenuItem value={", 3:30:00 PM"}>3:30 PM</MenuItem>
                    <MenuItem value={", 4:00:00 PM"}>4:00 PM</MenuItem>
                    <MenuItem value={", 4:30:00 PM"}>4:30 PM</MenuItem>
                    <MenuItem value={", 5:00:00 PM"}>5:00 PM</MenuItem>
                    <MenuItem value={", 5:30:00 PM"}>5:30 PM</MenuItem>
                    <MenuItem value={", 6:00:00 PM"}>6:00 PM</MenuItem>
                    <MenuItem value={", 6:30:00 PM"}>6:30 PM</MenuItem>
                    <MenuItem value={", 7:00:00 PM"}>7:00 PM</MenuItem>
                    <MenuItem value={", 7:30:00 PM"}>7:30 PM</MenuItem>
                    <MenuItem value={", 8:00:00 PM"}>8:00 PM</MenuItem>
                    <MenuItem value={", 8:30:00 PM"}>8:30 PM</MenuItem>
                    <MenuItem value={", 9:00:00 PM"}>9:00 PM</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* END DATE SELECTOR */}
              <Grid item xs={7}>
                <DatePicker
                  label="To"
                  value={currentDateEnd}
                  minDate={currentDateStart}
                  onChange={(value) => {
                    setCurrentDateEnd(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              {/* END TIME SELECTOR */}
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={currentTimeEnd}
                    label="Time"
                    onChange={(event) => {
                      setCurrentTimeEnd(event.target.value as string);
                    }}
                  >
                    <MenuItem value={", 9:00:00 AM"}>9:00 AM</MenuItem>
                    <MenuItem value={", 9:30:00 AM"}>9:30 AM</MenuItem>
                    <MenuItem value={", 10:00:00 AM"}>10:00 AM</MenuItem>
                    <MenuItem value={", 10:30:00 AM"}>10:30 AM</MenuItem>
                    <MenuItem value={", 11:00:00 AM"}>11:00 AM</MenuItem>
                    <MenuItem value={", 11:30:00 AM"}>11:30 AM</MenuItem>
                    <MenuItem value={", 12:00:00 PM"}>12:00 PM</MenuItem>
                    <MenuItem value={", 12:30:00 PM"}>12:30 PM</MenuItem>
                    <MenuItem value={", 1:00:00 PM"}>1:00 PM</MenuItem>
                    <MenuItem value={", 1:30:00 PM"}>1:30 PM</MenuItem>
                    <MenuItem value={", 2:00:00 PM"}>2:00 PM</MenuItem>
                    <MenuItem value={", 2:30:00 PM"}>2:30 PM</MenuItem>
                    <MenuItem value={", 3:00:00 PM"}>3:00 PM</MenuItem>
                    <MenuItem value={", 3:30:00 PM"}>3:30 PM</MenuItem>
                    <MenuItem value={", 4:00:00 PM"}>4:00 PM</MenuItem>
                    <MenuItem value={", 4:30:00 PM"}>4:30 PM</MenuItem>
                    <MenuItem value={", 5:00:00 PM"}>5:00 PM</MenuItem>
                    <MenuItem value={", 5:30:00 PM"}>5:30 PM</MenuItem>
                    <MenuItem value={", 6:00:00 PM"}>6:00 PM</MenuItem>
                    <MenuItem value={", 6:30:00 PM"}>6:30 PM</MenuItem>
                    <MenuItem value={", 7:00:00 PM"}>7:00 PM</MenuItem>
                    <MenuItem value={", 7:30:00 PM"}>7:30 PM</MenuItem>
                    <MenuItem value={", 8:00:00 PM"}>8:00 PM</MenuItem>
                    <MenuItem value={", 8:30:00 PM"}>8:30 PM</MenuItem>
                    <MenuItem value={", 9:00:00 PM"}>9:00 PM</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* TYPE SELECTOR */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="type-select-label">Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={globalContext.type}
                    label="Type"
                    onChange={(event) => {
                      globalContext.setType(event.target.value as string);

                      // Sort the list based on the type selection.
                      var list = globalContext.equipmentList;
                      list.sort((equipment) =>
                        (event.target.value as string) === equipment.type
                          ? -1
                          : 1
                      );
                      globalContext.setEquipmentList(list);
                    }}
                  >
                    <MenuItem value={"Cameras"}>Cameras</MenuItem>
                    <MenuItem value={"Lenses"}>Lenses</MenuItem>
                    <MenuItem value={"Lights"}>Lights</MenuItem>
                    <MenuItem value={"Accessories"}>Accessories</MenuItem>
                    <MenuItem value={"Studio Spaces"}>Studio Spaces</MenuItem>
                    <MenuItem value={"Content Cafe"}>Content Cafe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* SEARCH BUTTON */}
              <Grid item xs={12}>
                <Button
                  size={"large"}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 0,
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    if (
                      currentDateStart === null ||
                      currentDateStart === "" ||
                      currentTimeStart === null ||
                      currentTimeStart === ""
                    ) {
                      globalContext.setShowAlertDialog(true);
                      globalContext.setAlertMessage(
                        "From date/time is required!"
                      );
                      return;
                    }

                    if (
                      currentDateEnd === null ||
                      currentDateEnd === "" ||
                      currentTimeEnd === null ||
                      currentTimeEnd === ""
                    ) {
                      globalContext.setShowAlertDialog(true);
                      globalContext.setAlertMessage(
                        "To date/time is required!"
                      );
                      return;
                    }

                    if (
                      globalContext.type === null ||
                      globalContext.type === ""
                    ) {
                      globalContext.setShowAlertDialog(true);
                      globalContext.setAlertMessage(
                        "Equipment type is required!"
                      );
                      return;
                    }

                    var currentStart = new Date(
                      currentDateStart.toDateString() + currentTimeStart
                    );
                    var currentEnd = new Date(
                      currentDateEnd.toDateString() + currentTimeEnd
                    );

                    if (currentStart < currentEnd) {
                      var hours =
                        Math.abs(
                          currentEnd.getTime() - currentStart.getTime()
                        ) /
                        1000 /
                        3600;
                      if (hours < 2) {
                        globalContext.setShowAlertDialog(true);
                        globalContext.setAlertMessage(
                          "Minimum booking duration is 2 hours!"
                        );
                        return;
                      }

                      globalContext.setStartDate(currentStart);
                      globalContext.setEndDate(currentEnd);
                      globalContext.setShowAlertDialog(false);
                      globalContext.setAlertMessage("");
                      globalContext.setEquipmentList([]);
                      globalContext.setRefreshList(!globalContext.refreshList);
                      const collectionsStart =
                        document.getElementById("collections");
                      if (collectionsStart) {
                        collectionsStart.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        });
                      }
                    } else {
                      globalContext.setStartDate(new Date());
                      globalContext.setEndDate(new Date());
                      globalContext.setShowAlertDialog(true);
                      globalContext.setAlertMessage("Invalid input!");
                    }
                  }}
                >
                  Search
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                {globalContext.email === undefined ||
                globalContext.email === "" ? (
                  <GoogleLogin
                    onSuccess={(response) => {
                      if (response.credential) {
                        var data = JSON.parse(
                          JSON.stringify(jwtDecode(response.credential))
                        );
                        globalContext.setName(data["name"]);
                        globalContext.setEmail(data["email"]);
                        globalContext.setPicture(data["picture"]);
                        setCookie("name", data["name"]);
                        setCookie("email", data["email"]);
                        setCookie("picture", data["picture"]);
                      }
                    }}
                  />
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<ArticleIcon />}
                    onClick={() => {
                      globalContext.setShowProfileDialog(true);
                    }}
                    sx={{ borderRadius: 2 }}
                  >
                    My Profile
                  </Button>
                )}
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
}
