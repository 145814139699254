import React, {useContext} from "react";
import Equipment from "../data/Equipment";

interface GlobalContextType {
    showLoader: boolean;
    setShowLoader: (value: boolean) => void;
    startDate: Date;
    setStartDate: (value: Date) => void;
    endDate: Date;
    setEndDate: (value: Date) => void;
    type: string;
    setType: (value: string) => void;
    name: string;
    setName: (value: string) => void;
    phone: string;
    setPhone: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    picture: string;
    setPicture: (value: string) => void;
    totalHours: number;
    setTotalHours: (value: number) => void;
    totalHoursProcessed: number;
    setTotalHoursProcessed: (value: number) => void;
    totalAmount: number;
    setTotalAmount: (value: number) => void;
    showCheckoutDialog: boolean;
    setShowCheckoutDialog: (value: boolean) => void;
    alertMessage: string;
    setAlertMessage: (value: string) => void;
    showAlertDialog: boolean;
    setShowAlertDialog: (value: boolean) => void;
    refreshList: boolean;
    setRefreshList: (value: boolean) => void;
    equipmentList: Equipment[];
    setEquipmentList: (value: Equipment[]) => void;
    customizedEquipment: Equipment;
    setCustomizedEquipment: (value: Equipment) => void;
    selectedEquipments: Equipment[];
    setSelectedEquipments: (value: Equipment[]) => void;
    showAboutUsDialog: boolean;
    setShowAboutUsDialog: (value: boolean) => void;
    showCancellationPolicyDialog: boolean;
    setShowCancellationPolicyDialog: (value: boolean) => void;
    showContactUsDialog: boolean;
    setShowContactUsDialog: (value: boolean) => void;
    showPrivacyPolicyDialog: boolean;
    setShowPrivacyPolicyDialog: (value: boolean) => void;
    showTermsAndConditionsDialog: boolean;
    setShowTermsAndConditionsDialog: (value: boolean) => void;
    showProfileDialog: boolean;
    setShowProfileDialog: (value: boolean) => void;
    showCustomizationDialog: boolean;
    setShowCustomizationDialog: (value: boolean) => void;
}

export const GlobalContext = React.createContext<GlobalContextType>(
    {
        showLoader: false,
        setShowLoader: () => console.warn("No Provider for setShowLoader"),
        startDate: new Date('01/01/2000 12:00 am'),
        setStartDate: () => console.warn("No Provider for setStartDate"),
        endDate: new Date('01/01/2000 12:00 am'),
        setEndDate: () => console.warn("No Provider for setEndDate"),
        type: "",
        setType: () => console.warn("No Provider for setType"),
        name: "",
        setName: () => console.warn("No Provider for setName"),
        phone: "",
        setPhone: () => console.warn("No Provider for setPhone"),
        email: "",
        setEmail: () => console.warn("No Provider for setEmail"),
        picture: "",
        setPicture: () => console.warn("No Provider for setPicture"),
        totalHours: 0,
        setTotalHours: () => console.warn("No Provider for setTotalHours"),
        totalHoursProcessed: 0,
        setTotalHoursProcessed: () => console.warn("No Provider for setTotalHoursProcessed"),
        totalAmount: 0,
        setTotalAmount: () => console.warn("No Provider for setTotalAmount"),
        showCheckoutDialog: false,
        setShowCheckoutDialog: () => console.warn("No Provider for setShowCheckoutDialog"),
        alertMessage: "",
        setAlertMessage: () => console.warn("No Provider for setAlertMessage"),
        showAlertDialog: false,
        setShowAlertDialog: () => console.warn("No Provider for setShowAlertDialog"),
        refreshList:false,
        setRefreshList: () => console.warn("No Provider for setRefreshList"),
        equipmentList: [],
        setEquipmentList: () => console.warn("No Provider for setEquipmentList"),
        customizedEquipment: {
            id: "",
            name: "",
            type: "",
            description: "",
            price: "",
            quantity: 0,
            image: "",
            visibility: "",
            external: "",
            customization: "",
        },
        setCustomizedEquipment: () => console.warn("No Provider for setCustomizedEquipment"),
        selectedEquipments: [],
        setSelectedEquipments: () => console.warn("No Provider for setSelectedEquipments"),
        showAboutUsDialog:false,
        setShowAboutUsDialog: () => console.warn("No Provider for setShowAboutUsDialog"),
        showCancellationPolicyDialog: false,
        setShowCancellationPolicyDialog: () => console.warn("No Provider for setShowCancellationPolicyDialog"),
        showContactUsDialog: false,
        setShowContactUsDialog: () => console.warn("No Provider for setShowContactUsDialog"),
        showPrivacyPolicyDialog: false,
        setShowPrivacyPolicyDialog: () => console.warn("No Provider for setShowPrivacyPolicyDialog"),
        showTermsAndConditionsDialog:false,
        setShowTermsAndConditionsDialog: () => console.warn("No Provider for setShowTermsAndConditionsDialog"),
        showProfileDialog:false,
        setShowProfileDialog: () => console.warn("No Provider for setShowProfileDialog"),
        showCustomizationDialog: false,
        setShowCustomizationDialog: () => console.warn("No Provider for setShowCustomizationDialog"),
    }
);

export const useGlobalContext = () => useContext(GlobalContext);
