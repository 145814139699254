import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    InputAdornment,
    Grid,
    Slide,
    TextField, IconButton
} from "@mui/material";
import React, { useEffect } from "react";
import {useGlobalContext} from "../../context/GlobalContext";
import {TransitionProps} from "@mui/material/transitions";
import Typography from '@mui/material/Typography';
import axios from "axios";
import {format} from "date-fns";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EquipmentCheckout from "../EquipmentCheckout";
import CloseIcon from '@mui/icons-material/Close';
import { useCookies } from "react-cookie";
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckoutDialog() {
    const globalContext = useGlobalContext();
    const [cookies, setCookie, removeCookie] = useCookies(["name", "phone", "email", "picture"]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        var totalAmount = 0;
        globalContext.selectedEquipments.map((equipment) => {
            if (equipment.type === "Studio Spaces" || equipment.type === "Content Cafe") {
                totalAmount += (Number(globalContext.totalHoursProcessed)
                    * Number(equipment.price) * Number(equipment.quantity))
            } else {
                totalAmount += (Number(globalContext.totalHours)
                    * Number(equipment.price) * Number(equipment.quantity))
            }
        });
    globalContext.setTotalAmount(totalAmount);
    }, [globalContext.showCheckoutDialog])

    return (
        <Dialog
            open={globalContext.showCheckoutDialog}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={fullScreen}
            onClose={() => globalContext.setShowCheckoutDialog(false)}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {borderRadius: fullScreen ? 0 : 15,}
            }}
        >
            <DialogTitle fontSize={30} fontWeight={800} sx={{p: {xs: 2, md: 3}}}>
                {"Checkout"}
                {
                    fullScreen ? (
                        <IconButton sx={{color: 'black', float: 'right', pt: 1.5}} onClick={() => globalContext.setShowCheckoutDialog(false)}>
                            <CloseIcon/>
                        </IconButton>
                    ) : null
                }
            </DialogTitle>
            <DialogContent sx={{p: {xs: 2, md: 3}}}>
                <DialogContentText id="alert-dialog-slide-description">
                    We need your name, phone and email to proceed further.
                </DialogContentText>
                <Grid container spacing={3} sx={{pt: 3}}>
                    <Grid item xs={12} md={6}>
                        <TextField value={globalContext.name} label="Name" type='text' variant="outlined" fullWidth
                            InputLabelProps={{ shrink: true }} 
                                   onChange={(event) => {
                                       globalContext.setName(event.target.value as string);
                                       globalContext.setShowAlertDialog(false);
                                       globalContext.setAlertMessage("");
                                   }}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField value={globalContext.phone} label="Phone" type='text' variant="outlined" fullWidth
                            InputLabelProps={{ shrink: true }} 
                                   onChange={(event) => {
                                       globalContext.setPhone(event.target.value as string);
                                       globalContext.setShowAlertDialog(false);
                                       globalContext.setAlertMessage("");
                                   }}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                       inputMode: 'numeric',
                                   }}
                                   inputProps={{
                                       maxLength: '10',
                                       inputMode: 'numeric'
                                   }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={globalContext.email} label="Email" type='email' variant="outlined" fullWidth
                            InputLabelProps={{ shrink: true }} 
                                   onChange={(event) => {
                                       globalContext.setEmail(event.target.value as string);
                                       globalContext.setShowAlertDialog(false);
                                       globalContext.setAlertMessage("");
                                   }}/>
                    </Grid>
                </Grid>

                <Typography variant="h6" sx={{pt: 4, pb: 1, fontWeight: 'bold', fontSize: '1.6rem'}}>
                    Cart
                </Typography>
                <Grid container>
                    {/*TIME DETAILS*/}
                    <Grid item xs={6} sx={{textAlign: 'left', pt: 1}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Booking Start
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'center', pt: 1}}>
                        <Typography>
                            {format(globalContext.startDate, 'dd-MM-yyyy h:mm aa')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'left', pt: 1}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Booking End
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'center', pt: 1}}>
                        <Typography>
                            {format(globalContext.endDate, 'dd-MM-yyyy h:mm aa')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'left', pt: 1}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Total Hours
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'center', pt: 1}}>
                        <Typography>
                            {globalContext.totalHours}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{px: {xs: 4, md: 6}, pt: 2}}>
                        <Divider />
                    </Grid>

                    {/*HEADERS*/}
                    <Grid item xs={4} sx={{textAlign: 'left', pt: 2}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Equipment
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{textAlign: 'center', pt: 2}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Quantity
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'center', pt: 2}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Price
                        </Typography>
                    </Grid>
                    <Grid item xs={3}  sx={{textAlign: 'center', pt: 2}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            Amount
                        </Typography>
                    </Grid>

                    {/*EQUIPMENT DETAILS*/}
                    {
                        globalContext.selectedEquipments.map((equipment) =>
                            <EquipmentCheckout key={Math.random() * 100} id={equipment.id} name={equipment.name}
                                type={equipment.type} description={equipment.description} price={equipment.price}
                                quantity={equipment.quantity} image={equipment.image} visibility={equipment.visibility}
                                external={equipment.external} customization={equipment.customization} />
                        )
                    }

                    <Grid item xs={12} sx={{pt: 2}}>
                        <Divider />
                    </Grid>

                    {/*TOTAL DETAILS*/}
                    <Grid item xs={6} sx={{textAlign: 'center', pt: 1}}>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'center', pt: 1}}>
                        <Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}}>
                            Total:
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'center', pt: 1}}>
                        <Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}}>
                            {"₹ " + globalContext.totalAmount.toLocaleString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'center', pt: 3}}>
                        <Button size={'small'} variant="outlined" onClick={() => {
                            globalContext.setSelectedEquipments([]);
                            globalContext.setShowCheckoutDialog(false);
                            const equipments = globalContext.equipmentList;
                            globalContext.setEquipmentList([]);
                            setTimeout(function () {
                                globalContext.setEquipmentList(equipments)
                            }, 1000);
                        }}>
                            Clear Cart
                        </Button>
                    </Grid>
                </Grid>

                <Typography variant="h6" sx={{pt: 3, pb: 2, fontWeight: 'bold', fontSize: '1.6rem'}}>
                    Terms and Conditions
                </Typography>
                <li>100% advance payment for confirming the booking.</li>
                <li>For studio space, total hours doesn't include 9:00PM to 9:00AM</li>
                <li>Produce original passport or original driving licence at the time of pickup.</li>
                <li>100% charges on non-shoot days at location and 100% charges for cancellation. (No Refund)</li>
                <li>GST will be charged at 18%.</li>
                <li>A cheque to be issued in the favour of "SM Media LLP" at the time of pickup.</li>
                <li>Any loss caused by damage to rented equipment during shoot will be charged to your account entirely.</li>
            </DialogContent>
            <DialogActions sx={{p: 3}}>
                <Button size={'large'} fullWidth variant="contained"
                        onClick={() => {
                            if (globalContext.name === "" || globalContext.name.length <= 4) {
                                globalContext.setShowAlertDialog(true);
                                globalContext.setAlertMessage("Name is invalid!");
                                return;
                            }
                            if (globalContext.phone === "" || globalContext.phone.length !== 10) {
                                globalContext.setShowAlertDialog(true);
                                globalContext.setAlertMessage("Phone number is invalid!");
                                return;
                            }
                            if (globalContext.email === "" || !/\S+@\S+\.\S+/.test(globalContext.email)) {
                                globalContext.setShowAlertDialog(true);
                                globalContext.setAlertMessage("Email is invalid!");
                                return;
                            }

                            var equipments = "";
                            var equipmentData = ""
                            globalContext.selectedEquipments.map((equipment) => {
                                if (equipments === "") {
                                    equipments += (equipment.name + " X " + equipment.quantity);
                                } else {
                                    equipments += (", " + (equipment.name + " X " + equipment.quantity));
                                }
                                
                                if (equipmentData === "") {
                                    equipmentData += equipment.id + "-" + equipment.quantity
                                } else {
                                    equipmentData += "," + equipment.id + "-" + equipment.quantity
                                }
                            })
                            equipments = "[" + equipmentData + "] " + equipments

                            setCookie('phone', globalContext.phone);

                            axios.post("/slate-pencil-data-ingest", {
                                action: 'CREATE_PAYMENT_LINK',
                                name: globalContext.name,
                                phone: globalContext.phone,
                                email: globalContext.email,
                                enquiryDate: format(new Date(), 'dd-MM-yyyy h:mm aaa'),
                                fromDate: format(globalContext.startDate, 'dd-MM-yyyy h:mm aaa'),
                                toDate: format(globalContext.endDate, 'dd-MM-yyyy h:mm aaa'),
                                equipments: equipments,
                                amount: globalContext.totalAmount
                            }).then(response => {
                                if (response.status === 201) {
                                    // The backend response contains the newly created payment link url.
                                    // Redirect the user to the url.
                                    const paymentLink = response.data['payment_link'];

                                    if (paymentLink) {
                                        window.location.replace(paymentLink);
                                    } else {
                                        // Show the failed alert.
                                        globalContext.setShowAlertDialog(true);
                                        globalContext.setAlertMessage("Error, please try again!");
                                        globalContext.setShowCheckoutDialog(true);
                                        globalContext.setShowLoader(false);
                                    }
                                } else {
                                    // Show the failed alert.
                                    globalContext.setShowAlertDialog(true);
                                    globalContext.setAlertMessage("Error, please try again!");
                                    globalContext.setShowCheckoutDialog(true);
                                    globalContext.setShowLoader(false);
                                }
                            }).catch(error => {
                                // Show the failed alert.
                                globalContext.setShowAlertDialog(true);
                                globalContext.setAlertMessage("Error, please try again!");

                                globalContext.setShowLoader(false);
                                globalContext.setShowCheckoutDialog(true);
                            });

                            globalContext.setShowLoader(true);
                            globalContext.setShowCheckoutDialog(false);
                        }}
                        sx={{
                            borderRadius: 2
                        }}>
                    Checkout
                </Button>
            </DialogActions>
        </Dialog>
    );
}