import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Slide,
} from "@mui/material";
import React, { useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useGlobalContext } from "../../context/GlobalContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Equipment from "../../data/Equipment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomizationDialog() {
  const globalContext = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [options, setOptions] = React.useState<String[]>([]);

  const handleOptionChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value },
    } = event;
    setOptions(typeof value === "string" ? value.split(",") : value);
  };

  const validateDataAndAdd = () => {
    if (options.length != 2) {
      showAlert("Please select any two options!");
    } else {
      var customizedEquipment: Equipment = {
        ...globalContext.customizedEquipment,
      };
      customizedEquipment.name = customizedEquipment.name + "(" + options + ")";
      addEquipment(customizedEquipment);
      globalContext.setShowCustomizationDialog(false);
    }
  };

  const addEquipment = (customizedEquipment: Equipment) => {
    const equipments = globalContext.selectedEquipments;
    var equipmentCount = getEquipmentCount(equipments);
    // If the equipment is already present then increase the quantity.
    // If not present create a new equipment with the quantity 1.
    if (equipmentCount === 0) {
      var newEquipment: Equipment = { ...customizedEquipment };
      newEquipment.quantity = 1;
      equipments.push(newEquipment);
      showAlert("Added to cart!");
    } else {
      // Increase only the quantity.
      var existingEquipment = getEquipment(equipments);
      if (globalContext.customizedEquipment.quantity > equipmentCount) {
        existingEquipment.quantity = Number(equipmentCount) + Number(1);
        showAlert("Added to cart!");
      } else {
        showAlert("Maximum limit reached!");
      }
    }
    globalContext.setSelectedEquipments(equipments);
    setCount(getEquipmentCount(equipments));
  };

  const getEquipmentCount = (equipments: Equipment[]) => {
    var count = 0;
    equipments.map((existingEquipment) => {
      if (globalContext.customizedEquipment.id === existingEquipment.id) {
        count = existingEquipment.quantity;
      }
    });
    return count;
  };

  const showAlert = (message: string) => {
    globalContext.setShowAlertDialog(true);
    globalContext.setAlertMessage(message);
  };

  const getEquipment = (equipments: Equipment[]) => {
    var selectedEquipment: Equipment = {
      id: "",
      name: "",
      type: "",
      description: "",
      price: "",
      quantity: 0,
      image: "",
      visibility: "",
      external: "",
      customization: "",
    };
    equipments.map((existingEquipment) => {
      if (globalContext.customizedEquipment.id === existingEquipment.id) {
        selectedEquipment = existingEquipment;
      }
    });
    return selectedEquipment;
  };

  const [count, setCount] = useState(
    getEquipmentCount(globalContext.selectedEquipments)
  );

  return (
    <Dialog
      open={globalContext.showCustomizationDialog}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => globalContext.setShowCustomizationDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { borderRadius: fullScreen ? 0 : 15 },
      }}
    >
      <DialogTitle fontSize={30} fontWeight={800} sx={{ p: { xs: 2, md: 3 } }}>
        Customize {globalContext.customizedEquipment.name}
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <FormControl sx={{ m: 1, width: "-webkit-fill-available" }}>
          <InputLabel id="options-checkbox-label">Options</InputLabel>
          <Select
            labelId="options-checkbox-label"
            id="options-multiple-checkbox"
            multiple
            value={options}
            onChange={handleOptionChange}
            input={<OutlinedInput label="Options" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {globalContext.customizedEquipment.customization
              .split("|")
              .map((option) => {
                var value = option.split('\\')[0];
                var url = option.split('\\')[1];
                return (
                  <MenuItem key={value} value={value}>
                    <img src={url} alt={value} style={{ width: '150px', height: '150px', marginRight: '10px' }} />
                    {value}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => {
            validateDataAndAdd();
          }}
        >
          Add to cart
        </Button>
        <Button
          size={"large"}
          fullWidth
          variant="contained"
          sx={{ borderRadius: 2 }}
          onClick={() => globalContext.setShowCustomizationDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
