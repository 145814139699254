import {Box, Grid, Skeleton, Typography} from "@mui/material";
import EquipmentCard from "./EquipmentCard";
import {useGlobalContext} from "../context/GlobalContext";

export default function EquipmentList() {
    const globalContext = useGlobalContext();

    return (
        <Box id={'collections'} pt={{xs: 2, md: 5}} sx={{backgroundColor: 'primary.main'}} px={{xs: 2, md: 4}}>
            <Typography fontWeight={500} color={'primary.contrastText'} sx={{fontSize: {xs: '2.5rem', md: '3rem'}}}>
                Our Collection
            </Typography>
            {
                globalContext.equipmentList.length === 0 ? (
                    <Grid container spacing={3} sx={{pt: 3}}>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Skeleton sx={{ bgcolor: 'gray' }} height={300}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Skeleton sx={{ bgcolor: 'gray' }} height={300}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Skeleton sx={{ bgcolor: 'gray' }} height={300}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Skeleton sx={{ bgcolor: 'gray' }} height={300}/>
                        </Grid>
                    </Grid>
                ) : null
            }
            <Grid container spacing={3} sx={{pb: 8, pt: {xs: 3, md: 5}}}>
                {
                    globalContext.equipmentList.map((equipment) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{
                                width: '-webkit-fill-available',
                                display: equipment.visibility === 'Display' ? 'block' : 'none'
                            }}>
                                <EquipmentCard id={equipment.id} name={equipment.name} type={equipment.type}
                                    description={equipment.description} price={equipment.price}
                                    quantity={equipment.quantity} image={equipment.image}
                                    visibility={equipment.visibility} external={equipment.external}
                                    customization={equipment.customization}/>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Box>
    );
}