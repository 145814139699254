import {Grid, Typography} from "@mui/material";
import Equipment from "../data/Equipment";
import {useGlobalContext} from "../context/GlobalContext";

export default function EquipmentCheckout(equipment : Equipment) {
    const globalContext = useGlobalContext();
    var amount = "₹ " + (Number(globalContext.totalHours)
        * Number(equipment.price) * Number(equipment.quantity)).toLocaleString()
    if (equipment.type === "Studio Spaces" || equipment.type === "Content Cafe") {
        amount = "₹ " + (Number(globalContext.totalHoursProcessed)
            * Number(equipment.price) * Number(equipment.quantity)).toLocaleString()
    }

    return (
        <Grid container>
            <Grid item xs={4} sx={{textAlign: 'left', pt: 2}}>
                <Typography noWrap={true}>
                    {equipment.name}
                </Typography>
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'center', pt: 2}}>
                <Typography>
                    {equipment.quantity}
                </Typography>
            </Grid>
            <Grid item xs={3} sx={{textAlign: 'center', pt: 2}}>
                <Typography>
                    {"₹ " + equipment.price.toLocaleString()}
                </Typography>
            </Grid>
            <Grid item xs={3}  sx={{textAlign: 'center', pt: 2}}>
                <Typography>
                    {amount}
                </Typography>
            </Grid>
        </Grid>
    );
}